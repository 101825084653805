import React from 'react'
import Label from '../Label'
import { useMedia } from '../ResponsiveMedia'
import styles from './TextArea.module.scss'

const TextArea = React.forwardRef(
  (
    {
      errorText,
      id,
      label,
      rows = 4,
      cols = 20,
      onChange,
      required: isRequired = false,
      type = 'text',
      value,
      ...rest
    },
    ref
  ) => (
    <React.Fragment>
      {typeof label === 'string' ? (
        <Label error={Boolean(errorText)} htmlFor={id} required={isRequired}>
          {label}
        </Label>
      ) : (
        label
      )}
      <textarea
        {...rest}
        aria-required={isRequired}
        className={`${styles.base} ${rest.className ? rest.className : ''} ${
          errorText ? styles.error : ''
        }`}
        id={id}
        rows={rows}
        cols={cols}
        onChange={onChange}
        onBlur={rest.onBlur}
        placeholder={rest.placeholder ? rest.placeholder : ''}
        ref={ref}
        style={{ width: useMedia(rest.width) }}
        value={value}
        readOnly={rest.isReadOnly}
        disabled={rest.disabled}
      />
      {errorText && <span className={styles.errorText}>{errorText}</span>}
    </React.Fragment>
  )
)

export default TextArea
