import React from 'react'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

const Drawer = ({ children, onClose, open, styles, ...rest }) => (
  <Dialog
    role="dialog"
    aria-labelledby="dialog"
    onDismiss={onClose}
    isOpen={open}
    style={styles}
  >
    {children}
  </Dialog>
)

export default Drawer
