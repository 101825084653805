import React from 'react'
import IncidentForm from './IncidentForm'
import Drawer from '../../components/Drawer/Drawer'
import { IoClose } from 'react-icons/io5'
import styles from './ServiceNow.module.scss'

const ServiceNow = ({ open, handleClose }) => {
  return (
    <div>
      <Drawer
        open={open}
        onClose={handleClose}
        styles={{
          position: 'absolute',
          right: 0,
          width: '26%',
          minHeight: '100vh',
          background: 'white',
          overflowY: 'auto',
          overflowX: 'hidden',
          margin: '0',
          padding: '12px 0px',
        }}
      >
        <div className={styles.modal_header}>
          <h3>Report an Issue</h3>
          <p role="button" tabIndex={0}>
            <IoClose onClick={handleClose} />
          </p>
        </div>
        <IncidentForm handleClose={handleClose} />
      </Drawer>
    </div>
  )
}

export default ServiceNow
