import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import DisplayDateProvider from '../../../components/DisplayDateProvider/DisplayDateProvider'
import Spinner from '../../../components/Loader/Spinner'
import EmptyMap from '../../../components/Map/emptyMap'
import MapLegend from '../cdcMapLegendVertical'
import SideTableProvider from './SideTableProvider'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ProviderDetails from './ProviderDetails'
import ErrorLogs from '../../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../../components/AuthProvider'
import ProviderMapMarker from '../ProviderMapMarker'
import { lastweekEndDate } from './../dates'
// import ProviderMapMarkerLatest from '../../FluDashboard/ProviderMapMarkerLatest'
// import StateWithProviderMarker from '../../FluDashboard/StateWithProviderMarker'
const DashboardMap = ({ geo_type, geo_parent, zoomLocation }) => {
  const [getforecast_level, setforecast_level] = useState()
  const { user } = useAuthState()
  const mapRef = useRef()
  const geojson = useRef()
  //const lastweekEndDate = dayjs().day(+6).format('YYYY-MM-DD')
  // const entity = 'flu'

  const location = useLocation()
  const { loc, area } = useParams()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()

  const [activeCbsa, setActiveCbsa] = useState()
  const [getStats, setStats] = useState()
  const [getProviderDropDown, setProviderDropDown] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const {
    dataStartDate,
    dataEndDate,
    regionSelected,
    setRegionSelected,
    showProvidersMap,
  } = useGeoState()
  const [getProvider, setProvider] = useState()

  if (area === 'MSA') {
    setRegionSelected(area)
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluMapThreshold',
    `MSA/?geo_parent=${geo_parent}&geoid=${loc}`
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Msa Dashboard success',
          user: user,
        })
        setforecast_level(msaData.response_data)
      } else if (msaData.response_code === 404) {
        setforecast_level(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=MSA&geo_parent_code=' + geo_parent
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  

  let type = hash.length > 0 ? hash.replace('#', '') : ''
  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProviders',
    geo_parent + `/${loc}?disease_type=FLU&filter=${type}`
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])

  const { data: providerDropDown, error: dropdownError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProvidersFilter',
    geo_parent + `/${loc}`
  )
  useEffect(() => {
    if (providerDropDown !== null && providerDropDown !== undefined) {
      if (providerDropDown.response_code === 200) {
        setProviderDropDown(providerDropDown.response_data)
      } else {
        setErrorCode(providerDropDown.response_code)
        setErrorMessage(providerDropDown.response_message)
        setErrorSubject(providerDropDown.response_subject)
        seterrorOccured(true)
      }
    }
  }, [providerDropDown])

  if (
    stateDataError ||
    MapError ||
    errorOccured ||
    MapmsaError ||
    activeCbsasDataError ||
    dropdownError
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  const chooseMessage = (data) => {
    setProvider(data)
  }

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'MSA' && (
            <>
              {getStats !== undefined && getProviderDropDown !== undefined ? (
                <SideTableProvider
                  dropdownData={getProviderDropDown}
                  stateData={getStats}
                  chooseMessage={chooseMessage}
                  mapRef={mapRef}
                />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'MSA' && (
          <>
            {getforecast_level !== undefined &&
            getMapStates !== undefined &&
            activeCbsa !== undefined ? (
              <>
                {getProvider && (
                  <ProviderDetails
                    providerData={getProvider}
                    chooseMessage={chooseMessage}
                  />
                )}
                {!showProvidersMap && getStats && (
                  <>
                    <div className={`${styles.providerHeaderCollection}`}>
                      <DisplayDateProvider
                       textShow={'CDO Locations for Epiweek '}
                        showWeek={true}
                        lastweekEndDate={lastweekEndDate}
                      />
                    </div>
                    {getStats && getforecast_level && (
                      <MapLegend
                        geojson={geojson}
                        provider={true}
                        thresholdVal={getforecast_level}
                      />
                    )}
                    {getforecast_level && (
                      <ProviderMapMarker
                        geojson={geojson}
                        thresholdVal={getforecast_level}
                        providers={getStats}
                        geoid={loc}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <EmptyMap />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
