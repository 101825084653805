import { useState } from 'react'
import getThresholdValue from '../../utils/getThresholdValue'
export default function MapLegend(props) {
  const provider = props.provider 
  const geojson = props.geojson
  const thresholdVal = props.thresholdVal
  const[getistrue, setistrue]=useState(true)
  if (provider)
  {
    const removelayercolor = () => {
       setistrue(!getistrue)
      const refStateMap = geojson
      refStateMap.current.eachLayer(function (layer) {              
        layer.setStyle({
        fillColor: getistrue ? 'none': getThresholdValue(thresholdVal).color,
        weight: 1,
        opacity: 1,
        color: getThresholdValue(thresholdVal).OutlineColor,
        dashArray: '1.5',
        fillOpacity: 0.7,
      })
      })
    }
       
    const svgstyle = {
      viewBox:"0 0 90 150",
      height: "19",
      width: (thresholdVal===5 || thresholdVal===1) ? "85": thresholdVal===3 ? "80": "55",
      align: "center",
      MarginTop:"10",
    };
    const rectstyle = {
      x:"1",
      y:"1",
      width:"16",
      height:"16",
      fill:getThresholdValue(thresholdVal).color,
      stroke:getThresholdValue(thresholdVal).OutlineColor,
      strokeWidth:"1px",
    };
    return (
       <svg
       style={svgstyle}
      {...props}
    >
        <rect
        
          style={rectstyle}
      ></rect> 

      <text
       
        x="25"
        y="13"
        style={{
          width: '57px',
          height: '16px',
         fontWeight:'400',
          fontSize: '12.64px',
          fill: '#0c55b8',         
          cursor:'pointer',
          textDecoration: 'underline'
        }}
      >
      <a  onClick={removelayercolor} >{ getThresholdValue(thresholdVal).threshold}</a>  
      </text>  
      </svg>)
  }
  else {
  return (
    <svg viewBox="0 0 480 25" height="25" width="480" align="center" {...props}>
    
    <rect
      x="0"
      y="0"
      width="16"
      height="16"
      fill="#C40000"
      stroke="#FFFFFF"
      strokeWidth="1px"
    ></rect>
    <text
      x="25"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Very High
    </text>
    <rect
      x="90"
      y="0"
      width="16"
      height="16"
      fill="#FB8D7B"
      stroke="#990000"
      strokeWidth="1px"
    ></rect>
    <text
      x="115"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      High
    </text>
    <rect
      x="150"
      y="0"
      width="16"
      height="16"
      fill="#FFFFF0"
      stroke="#AD8D01"
      strokeWidth="1px"
    ></rect>
    <text
      x="175"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Medium
    </text>
    <rect
      x="230"
      y="0"
      width="16"
      height="16"
      fill="#6CB2A2"
      stroke="#0D5E4F"
      strokeWidth="1px"
    ></rect>
     <text
      x="255"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Low
    </text>
    <rect
      x="290"
      y="0"
      width="16"
      height="16"
      fill="#218371"
      stroke="#FFFFFF"
      strokeWidth="1px"
    ></rect>

    <text
      x="315"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Minimal
    </text>
    <line x1="370" y1="0" x2="370" y2="18" stroke="#CBCCCD" strokeWidth={2} />
    <rect
      x="380"
      y="0"
      width="16"
      height="16"
      fill="#6D6F70"
      stroke="#6D6F70"
      strokeWidth="1px"
    ></rect>
    
    <text
      x="405"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      No Data
    </text>
    

  
  </svg>
  )
      }
}