import React, { useEffect, useState, useRef } from 'react'
import L from 'leaflet'
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  GeoJSON,
  ZoomControl,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import getThresholdValue from '../../utils/getThresholdValue'
import { useGeoState } from '../../components/GeoProvider'
import iconimg from '../../assets/images/provider_location.svg'

const ProviderMapMarker = ({ geojson, thresholdVal, providers, geoid }) => {
  const { getPinPopup, regionSelected } = useGeoState()
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const [getZIP3JsonData, setZIP3JsonData] = useState()
  const [getmsaBorderStyle, msaBorderStyle] = useState()
  const [getzoomPostion, zoomPostion] = useState(4.0)
  const [map, setMap] = useState(false)
  const [getMapPosition, setMapPostion] = useState([39.82, -98.58])
  //const geojson = useRef()
  const chartRef = useRef()

  // var colorcode
  // var OutlineColor
  useEffect(() => {
    let url = `${window.location.origin}/metropolitan_statistical_area.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        if (regionSelected.toUpperCase()==='MSA')
          {
        data.features.forEach((feat) => {
          if (feat.properties.geoid === geoid) {            
            setCBSAJsonData(feat)
            
          }
        })
      }
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])

  useEffect(() => {
    let url = `${window.location.origin}/zip3_origanal_file.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        if (regionSelected.toUpperCase()==='ZIP3')
          {
            data.features.forEach((feat) => {
              if (feat.properties.geoid === geoid) {            
                setZIP3JsonData(feat)                
              }
            })
          }
        
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])

  function PointsLayer(props) {
    const { data, selectedIndex } = props
    return data.map((item, index) => (
      <PointMarker
        key={index}
        content={item}
        center={{ lat: item.latitude, lng: item.longitude }}
        openPopup={selectedIndex === index}
      />
    ))
  }

  const MarkerIcon = () =>
    L.icon({
      iconUrl: iconimg,
      iconRetinaUrl: iconimg,
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(25, 30),
    })

  function PointMarker(props) {
    const markerRef = useRef(null)
    const { center, content, openPopup } = props
    useEffect(() => {
      if (openPopup) {
        markerRef.current.openTooltip()

        // markerRef.current.leafletElement.openPopup();
      }
    }, [openPopup])

    const ToolTipFormatter = ({ content }) => {
      let notAv = content.originating_business === 'Name Not Available'
      return (
        <div
          style={{
            fontFamily: 'Optum Sans',
            width: '100%',
            background: 'transparent',
            borderRadius: '4px',
            padding: '16px 0 16px 16px',
            marginRight: '20px',
          }}
        >
          <div style={{ color: '#424242' }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#002677',
              }}
            >
              {content.location_code}:{' '}
              {notAv !== true ? (
                content.originating_business
              ) : (
                <span style={{ color: '#4b4d4f', fontWeight: '400' }}>
                  {' '}
                  ({content.originating_business})
                </span>
              )}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#4B4D4F',
                marginTop: '16px',
              }}
            >
              {content.primary_location_type_formated}{' '}
              {content.primary_location_type_formated !== 'Other'
                ? content.primary_location_sub_type_formated
                : ''}
            </div>
            <div
              style={{
                marginRight: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'center',
                color: '#4b4d4f',
                marginTop: '16px',
              }}
            >
              <div style={{ paddingRight: '10px' }}>
                <img
                  style={{ height: '19px', width: '16px' }}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr13AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFwSURBVHgBnVNdSsNAEJ7ZbsBohXiDeIPeQEWQii9RDPjYnkA9QXOD2hPYRyGgfbMWpHqD3MB6gsaf0kqzGXdTUiVu2tgPEmZ25vsymZlFyKDs9naAYgcRHAKwk0OCgOQjyPAmt/uvv/MxNSznzor4egOQLmABKIa2IcaXYec4nAvMyGZfehUoAlkNj8Z7SoQpXxhmszB59tlKUq0yrbN7OxL4AiuB7TIhsAGrQjabEWBFXyW0+XS8xUu0rWwtX04Ky26XdMFIGHY6MsvpW5HxNdTlMcjBWvz+9uNMrLw8LhdmIJfEzgamJbO5dvLoKaH8PmHAKMaONsSgxkvTQWSYQ/mPNV0OxRQwQL1AITDRYp/+wbPcrCf4J9RKj/yjIGki51SXRYfF6Riqi5UUoV7hzeGAgLyidJWbjng+xpFfbcnNuFrORi/JTWvJxjfd7nVe19VGfvjVeubsL3QiOnKuQFYkj7wUG27vvHz6sPC2fgNzepm05hkshQAAAABJRU5ErkJggg=="
                  alt="location"
                />
              </div>
              {content.address_line1} {content.address_line2}, <br />
              {content.geo_name}
            </div>
          </div>
        </div>
      )
    }

    const MarkerClick = (event) => {      
      let mapInst = chartRef.current
      //map.fitBounds(event.layer.getBounds());
      mapInst.flyTo(event.latlng, 16, {
        animate: true,
        duration: 5,
      })
    }
    return (
      <Marker
        
       eventHandlers={{ click: MarkerClick }}  
        ref={markerRef}
        position={center}
        icon={MarkerIcon()}
      >
        <Tooltip>
          <ToolTipFormatter content={content} />
        </Tooltip>
      </Marker>
    )
  }

  const renderTooltip = (feature) => {

    
    var content = providers.length > 0 ? '' : `Providers not available for `
    return `${content} <b>${feature.properties.NAME}</b>`
  }
  
  const renderZip3Tooltip = (feature) => {    
    if( providers.length > 0) 
      {      
        return `<b>${providers[0].geo_name}</b>`
      } 
      else {
       return `Providers not available for ${feature.properties.geoid}`
      }
   
  }

  const onEachFeature = (feature, layer) => {
    const tooltipChildren =  regionSelected.toUpperCase()==='MSA' ? renderTooltip(feature) : renderZip3Tooltip(feature)
    const popupContent = `<Tooltip> ${tooltipChildren} </Tooltip>`
    layer.bindTooltip(popupContent)
    layer.on({
      click: zoomLocation,
    })

    layer.setStyle({
      fillColor: getThresholdValue(thresholdVal).color,
      weight: 1,
      opacity: 1,
      color: getThresholdValue(thresholdVal).OutlineColor,
      dashArray: '1.5',
      fillOpacity: 0.7,
    })

    setMap(true)
  }

  useEffect(() => {
    let interval    
      if (providers.length === 0) {
        zoomPostion(4.0)
        interval = setInterval(() => { zoomLocation()}, 0.1)
        return () => clearInterval(interval)
      } else {        
        zoomPostion(8.4)
        setMapPostion([providers[0].latitude, providers[0].longitude])
      }        
  }, [map,providers])

 
  function zoomLocation1(e) {
    chartRef.current.flyToBounds(e.target.getBounds())  
  }

  const zoomLocation = () => {
    if (
      chartRef.current &&     
      geojson.current
    ) {
      let mapInst = chartRef.current
      const group = geojson.current //get native featureGroup instance

      // mapInst.setMinZoom(mapInst.getZoom() - 1)
      // mapInst.fitBounds(group.getBounds(), { maxZoom: 5 })
      // mapInst.getBoundsZoom(group.getBounds(), { reset: true })
      mapInst.fitBounds(group.getBounds())
      //mapInst.flyToBounds(group.getBounds(), 40,{ animate: true, duration: 1000 } );

      //mapInst.setZoom(10)
      //setTimeout(function(){ mapInst.setZoom(10);}, 3000);
      // mapInst.setMinZoom(mapInst.getZoom())
      // mapInst.getBoundsZoom(group.getBounds())

    }
  }



  const onDrag = (event) => {
    console.log(event.target.getCenter());
    var southWest = L.latLng(-89.98155760646617, -180),
    northEast = L.latLng(89.99346179538875, 180);
    var bounds1 = L.latLngBounds(southWest, northEast);
   
  };
  return  (
    <div id="content">
      <div id="map">
        <MapContainer key={getMapPosition}
          zoom={getzoomPostion}
          center={getMapPosition}
          zoomControl={false}
          ref={chartRef}
          style={{ height: '81vh', width: '100wh' }}
          dragging={false}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            noWrap={true}
            draggable ={false}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <PointsLayer selectedIndex={getPinPopup} data={providers} />
          {getCBSAJsonData && providers && regionSelected.toUpperCase()==='MSA' && (
            <GeoJSON
              ref={geojson}
              //style={getmsaBorderStyle}
              data={getCBSAJsonData}
              onEachFeature={onEachFeature}
            />
          )}

          {getZIP3JsonData && providers && regionSelected.toUpperCase()==='ZIP3' && (
            <GeoJSON
              ref={geojson}              
              data={getZIP3JsonData}
              onEachFeature={onEachFeature}
            />
          )}
        </MapContainer>
      </div>
    </div>
  )
}
export default ProviderMapMarker