import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { useAuthState } from '../../components/AuthProvider'
import styles from './Home.module.scss'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import getVectorImage from '../../utils/getVectorImage'
const Home = () => {
  // const { clearSession } = useAuthDispatch()
  const history = useHistory()
  // React.useEffect(() => {
  //   clearSession()
  // }, [0])
  const { isAuthenticated } = useAuthState()

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />
  } else {
    return (
      <Layout>
        <Content backgroundColor="#D9F6FA">
          <div className={styles.base}>
            <div className={styles.maindivFormat}>
              <div style={{ width: '721px', marginBottom: '-7px' }}>
                {getVectorImage('illustration', '550px', '')}
              </div>
              <div
                style={{ width: '35%', marginTop: '48px', display: 'inline' }}
              >
                <div>
                  <div className={styles.formheader}>
                    Welcome to the Infectious Disease Platform
                  </div>
                  <div className={styles.formheadertitle}>
                    Please sign in with your One Healthcare ID.{' '}
                  </div>
                </div>
                <div className={styles.buttondiv}>
                  {' '}
                  <div
                    className={styles.button}
                    onClick={() => history.push('/ohid-login')}
                  >
                    {' '}
                    One Healthcare ID Sign In{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    )
  }
}
export default Home
